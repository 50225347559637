<template>
  <div class="elv-valuation-detail-content-screening">
    <Screening :show-export="false">
      <template #screening>
        <div class="elv-valuation-screening-item">
          <span class="elv-valuation-screening-item__label">{{ t('common.sort') }}:</span>
          <el-select
            v-model="sortValue"
            placeholder=" "
            popper-class="elv-valuation-screening-popper"
            class="elv-valuation-screening-sort"
            @change="onChangeSort"
          >
            <el-option v-for="item in sortOptions" :key="item.value" :label="item.label" :value="item.value" />
          </el-select>
        </div>
        <div v-if="props.isShowFilterButton" class="elv-valuation-screening-item">
          <div @click="onOpenFilterOverlay">
            <div v-if="!filterTotal" class="elv-valuation-screening-item-filter-add">
              <SvgIcon name="add-line" width="14" height="14" />{{ t('common.addFilter') }}
            </div>
            <div v-else class="elv-valuation-screening-item-filter-count">
              {{ t('common.filter') }}: {{ filterTotal }}
            </div>
          </div>
        </div>
      </template>
      <!-- <template #operating>
        <elv-export-button
          ref="exportButtonRef"
          :params="props.exportParams"
          class="elv-valuation-header-main-right__export"
          :no-permission="
            ['MEMBER', ''].includes(currentEntityPermission.value?.role) &&
            !currentEntityPermission.value?.transaction?.export
          "
        />
      </template> -->
    </Screening>
  </div>
</template>

<script setup lang="ts">
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { useI18n } from 'vue-i18n'
import timezone from 'dayjs/plugin/timezone'
import { valuationFilterSaveType } from '@/config/valuation'
import { useValuationStore } from '@/stores/modules/valuation'
import Screening from '@/pages/Financials/Project/components/Screening.vue'

type ExportParamsType = {
  type: string
  extra: Object
}

const props = defineProps({
  exportParams: {
    type: Object as () => ExportParamsType,
    default: () => {
      return {}
    }
  },
  filterType: {
    type: String,
    default: valuationFilterSaveType.FUTURE
  },
  isShowFilterButton: {
    type: Boolean,
    default: true
  }
})

const emit = defineEmits(['onChangeSort', 'onOpenFilterOverlay'])

dayjs.extend(utc)
dayjs.extend(timezone)
const { t } = useI18n()
const route = useRoute()
const valuationStore = useValuationStore()

const exportButtonRef = ref()
// eslint-disable-next-line no-unused-vars
const typeValue = ref('ALL')
const sortValue = ref('DESC')

// eslint-disable-next-line no-unused-vars
const typeOptions = reactive([
  {
    value: 'ALL',
    label: t('project.transaction.allTransactions')
  }
])

const sortOptions = reactive([
  {
    value: 'DESC',
    label: t('common.newestToOldest')
  },
  {
    value: 'ASC',
    label: t('common.oldestToNewest')
  }
])

const entityId = computed(() => {
  return String(route?.params?.entityId)
})

const filterTotal = computed(() => {
  const filterSaveData = valuationStore.valuationFilterList.find((item: any) => {
    return item.type === props.filterType
  })
  if (filterSaveData) {
    return (
      filterSaveData?.list.find((item: any) => {
        return item.entityId === entityId.value
      })?.total ?? 0
    )
  }
  return 0
})

/**
 * 切换排序类型事件分发
 */
const onChangeSort = () => {
  emit('onChangeSort', sortValue.value)
}

/**
 * 打开数据筛选弹窗事件分发
 */
const onOpenFilterOverlay = () => {
  emit('onOpenFilterOverlay')
}

defineExpose({
  exportButtonRef
})
</script>

<style lang="scss">
.elv-valuation-detail-content-screening {
  height: 47px;
  padding: 0px 20px;
  display: flex;
  align-items: center;

  .elv-screening-container {
    padding: 0px;
  }
}

.elv-valuation-screening-item {
  padding-right: 16px;
  margin-right: 16px;
  border-right: 1px solid #dde1e6;
  display: flex;
  align-items: center;

  &:last-child {
    border-right: none;
  }

  .elv-valuation-screening-item__label {
    color: #838d95;
    font-family: 'Plus Jakarta Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .el-select {
    height: 15px;
    width: fit-content;

    .el-select__wrapper {
      height: 15px;
      min-height: 15px;
      border: none !important;
      box-shadow: none !important;
      padding: 0;
      padding-left: 8px;

      .el-select__selection {
        width: fit-content;

        .el-select__placeholder {
          position: relative;
          top: 0;
          transform: none;
          color: #1e2024;
          font-family: 'Plus Jakarta Sans';
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          width: fit-content;
          overflow: visible;
        }
      }

      .el-select__suffix {
        .el-icon {
          margin-left: 4px;

          svg {
            width: 12px;
            height: 12px;
          }
        }
      }
    }
  }

  .elv-valuation-screening-item-filter-add {
    display: flex;
    align-items: center;
    color: #1e2024;
    font-family: 'Plus Jakarta Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;

    svg {
      fill: #939ba2;
      margin-right: 8px;
    }
  }

  .elv-valuation-screening-item-filter-count {
    display: flex;
    padding: 0px 11px;
    height: 19px;
    box-sizing: border-box;
    align-items: center;
    border-radius: 10px;
    background: #5e85eb;
    color: #fff;
    font-family: 'Plus Jakarta Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
  }
}

.elv-valuation-header-main-right__export {
  float: right;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  height: 32px;
  border-radius: 2px;
  font-family: 'Plus Jakarta Sans';
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #1e2024;
  cursor: pointer;
  margin-left: 8px;

  svg {
    fill: #838d95;
    margin-right: 8px;
  }

  .el-icon.is-loading {
    svg {
      margin-right: 0px;
    }
  }

  &:hover {
    color: #1343bf;
    background-color: #fff;

    svg {
      fill: #1343bf;
    }
  }
}

.elv-valuation-screening-popper.el-popper.el-select__popper {
  transform: translateY(-6.5px);

  .el-select-dropdown__item {
    height: 32px;
    color: #1e2024;
    font-family: 'Plus Jakarta Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    &.hover,
    &.is-hovering {
      background: #f9fafb;
    }

    &.selected,
    &.is-selected {
      color: #1753eb;
      font-weight: 500;
    }

    &.is-disabled {
      background-color: #f5f7fa !important;
    }
  }
}
</style>
