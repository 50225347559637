<template>
  <div class="elv-valuation-calculation-content-panel">
    <ListScreening
      :filterType="props.tableType"
      @onChangeSort="onChangeSort"
      @onOpenFilterOverlay="onOpenFilterOverlay"
    />
    <div class="elv-valuation-calculation-content-panel-table-wrapper">
      <CustomTable
        ref="tableListRef"
        v-loading="props.tableLoading"
        class="elv-valuation-calculation-content-panel-table"
        border
        stripe
        :table-data="props.tableData.list"
        :table-header="props.headerList"
        :pagination-data="props.pageParams"
        :table-count-number="props.tableData.filteredCount"
        :table-height="tableHeight"
        @onChangePage="onChangePage"
        @onChangePageSize="onChangePageSize"
      >
        <template #origin="{ data }">
          <p class="elv-valuation-calculation-content-panel-table-origin">
            {{ t(currentCalculationTableData(data).origin) }}
          </p>
        </template>
        <template #adjusting="{ data }">
          <p>{{ data.adjustmentNo }}</p>
        </template>
        <template #period="{ data }">
          <VerticalTextCell
            align="left"
            :up-text="currentCalculationTableData(data).startDate"
            :down-text="currentCalculationTableData(data).endDate"
          />
        </template>
        <template #account="{ data }">
          <IconWithTitleCell
            class="elv-valuation-calculation-content-panel-table-account"
            :icon="data?.entityAccount?.platform?.logo || ''"
            :title="data?.entityAccount?.name || ''"
          />
        </template>
        <template #symbol="{ data }">
          <p>
            {{ data.asset.derivative?.name || '-' }}
          </p>
        </template>
        <template v-if="props.tableType === valuationFilterSaveType.OPTIONS" #quoteAsset="{ data }">
          <IconWithTitleCell
            :icon="calculationAssetInfo(data).icon || ''"
            :title="calculationAssetInfo(data).title || ''"
            :title-weight="500"
          />
        </template>
        <template v-if="props.tableType === valuationFilterSaveType.FUTURE" #positionSide="{ data }">
          <IconWithTitleCell
            :svgName="positionSideInfo(data).svgName || ''"
            :title="t(positionSideInfo(data).label) || '-'"
            :title-weight="400"
          />
        </template>
        <template v-if="props.tableType === valuationFilterSaveType.FUTURE" #marginAsset="{ data }">
          <IconWithTitleCell
            :icon="calculationAssetInfo(data).icon || ''"
            :title="calculationAssetInfo(data).title || ''"
            :title-weight="500"
          />
        </template>
        <template #unrealizedAndChange="{ data }">
          <VerticalTextCell
            :up-text="upAmount(data).text"
            :up-desc="upAmount(data).desc"
            :down-text="downAmountFC(data).text"
            :down-desc="downAmountFC(data).desc"
            :up-weight="500"
            :up-font-size="'12px'"
            :align="'right'"
          />
        </template>
        <template #equality>
          <span class="elv-valuation-calculation-content-panel-table-symbol">=</span>
        </template>
        <template #ending="{ data }">
          <p class="elv-valuation-calculation-content-panel-table-ending">
            {{
              typeof data?.endingBalance === 'string'
                ? formatNumberToSignificantDigits(data?.endingBalance, 2, '', false)
                : '-'
            }}
            <SvgIcon
              v-if="typeof data?.endingBalance === 'string'"
              name="transaction-launch"
              width="14"
              height="14"
              @click="onOpenRawDataEndingDialog(data)"
            />
          </p>
        </template>
        <template #deleteSymbol>
          <p class="elv-valuation-calculation-content-panel-table-symbol">-</p>
        </template>
        <template #beginning="{ data }">
          <p class="elv-valuation-calculation-content-panel-table-beginning">
            {{
              typeof data?.beginningBalance === 'string'
                ? formatNumberToSignificantDigits(data?.beginningBalance, 2, '', false)
                : '-'
            }}
          </p>
        </template>
        <template #addSymbol>
          <p class="elv-valuation-calculation-content-panel-table-symbol">+</p>
        </template>
        <template #realizedPAndL="{ data }">
          <p class="elv-valuation-calculation-content-panel-table-realized">
            {{
              typeof data?.totalRealizedAmount === 'string'
                ? formatNumberToSignificantDigits(data?.totalRealizedAmount, 2, '', false)
                : '-'
            }}
            <SvgIcon
              v-if="typeof data?.totalRealizedAmount === 'string'"
              name="transaction-launch"
              width="14"
              height="14"
              @click="onOpenRawDataRealizedDialog(data)"
            />
          </p>
        </template>
        <template #journalNo="{ data }">
          <p>{{ data.journalActivity?.journalNo || '-' }}</p>
        </template>
        <template #beginningHeader>
          <div
            class="elv-valuation-calculation-content-panel-table-beginning-header"
            :title="t('valuation.calculationBeginningTip')"
          >
            <span>{{ t('valuation.future.beginning') }}</span>
            <SvgIcon name="information-tip-icon" :width="12" :height="12" />
          </div>
        </template>
      </CustomTable>
    </div>
  </div>
</template>

<script setup lang="ts">
import dayjs from 'dayjs'
import { isEmpty } from 'lodash-es'
import { positionOptions } from '@/config'
import ListScreening from './ListScreening.vue'
import { useEntityStore } from '@/stores/modules/entity'
import { useGlobalStore } from '@/stores/modules/global'
import CustomTable from '../../../components/CustomTable.vue'
import { formatNumber, formatNumberToSignificantDigits } from '@/lib/utils'
import VerticalTextCell from '@/components/Base/Table/VerticalTextCell.vue'
import IconWithTitleCell from '@/components/Base/Table/IconWithTitleCell.vue'
import { adjustmentOriginOptions, valuationFilterSaveType } from '@/config/valuation'
import { FutureAdjustmentItemType, OptionsAdjustmentItemType } from '#/ValuationTypes'

const props = defineProps({
  tableType: {
    type: String,
    default: valuationFilterSaveType.FUTURE
  },
  headerList: {
    type: Array as PropType<any[]>,
    default: () => []
  },
  tableData: {
    type: Object as PropType<any>,
    default: () => {
      return {
        totalCount: 0,
        list: []
      }
    }
  },
  pageParams: {
    type: Object as PropType<any>,
    default: () => {
      return {
        page: 1,
        limit: 20,
        sortField: 'datetime',
        sortDirection: 'DESC'
      }
    }
  },
  tableLoading: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits([
  'onChangeSort',
  'onChangePage',
  'onChangePageSize',
  'onOpenFutureFilterOverlay',
  'onOpenRawDataEndingDialog',
  'onOpenRawDataRealizedDialog'
])

const { t } = useI18n()
const entityStore = useEntityStore()
const globalStore = useGlobalStore()
const { entityDetail } = storeToRefs(entityStore)

const upAmount = computed(() => {
  return (data: any) => {
    if (!data?.amount) return { text: '-', desc: '' }
    const value = formatNumberToSignificantDigits(data?.amount, 2, '', false)
    return {
      text: `${value}`,
      desc: `${formatNumber(data?.amount, 20) || ''}`
    }
  }
})

const tableHeight = computed(() => {
  const updateProjectTipHeight = globalStore.freeProjectUpdateInfo(entityStore.entityDetail?.projectId) ? '28px' : '0px'
  return `calc(100vh - 341px - ${updateProjectTipHeight})`
})

const downAmountFC = computed(() => {
  return (data: any) => {
    if (!data?.amountFC) return { text: '-', desc: '' }
    const fcValue = formatNumberToSignificantDigits(
      data?.amountFC,
      2,
      `${entityDetail?.value.underlyingCurrency?.sign || `${entityDetail?.value.underlyingCurrency?.showSymbol} `}`
    )

    const descText = `${
      entityDetail?.value.underlyingCurrency?.sign || entityDetail?.value.underlyingCurrency?.showSymbol
    }${data?.amountFC} `
    return {
      text: fcValue,
      desc: descText
    }
  }
})

const currentCalculationTableData = computed(() => {
  return (row: FutureAdjustmentItemType) => {
    const rowData = { origin: '', startDate: '', endDate: '' }
    rowData.origin = adjustmentOriginOptions.find((item) => item.value === row.origin)?.label || ''
    rowData.startDate = dayjs(row.beginningDate)
      .tz(entityStore.entityDetail?.timezone ?? 'UTC')
      .format('YYYY/MM/DD')
    rowData.endDate = row.endingDate?.length
      ? `-${dayjs(row.endingDate)
          .tz(entityStore.entityDetail?.timezone ?? 'UTC')
          .format('YYYY/MM/DD')}`
      : ''
    return rowData
  }
})

const calculationAssetInfo = computed(() => {
  return (data: any) => {
    const assetData = { icon: '', title: '-' }
    if (isEmpty(data.asset?.underlyingCurrency) && isEmpty(data.asset?.derivative)) {
      return assetData
    }
    if (isEmpty(data.asset?.underlyingCurrency)) {
      assetData.title = data.asset?.derivative?.marginAssetCurrency?.showSymbol || '-'
      assetData.icon = data.asset?.derivative?.marginAssetCurrency?.logo || ''
    } else {
      assetData.title = data.asset?.underlyingCurrency?.showSymbol || '-'
      assetData.icon = data.asset?.underlyingCurrency?.logo || ''
    }
    return assetData
  }
})

const positionSideInfo = computed(() => {
  return (data: any) => {
    return positionOptions.find((item) => item.value === data.positionSide) || { label: '', svgName: '' }
  }
})

/**
 * 列表排序类型 时间排序
 * @param data 排序字段 DESC/ASC
 */
const onChangeSort = (data: string) => {
  emit('onChangeSort', data)
}

/**
 * CalculationTable 分页器改变页码
 * @param page 页码
 */
const onChangePage = (page: number) => {
  emit('onChangePage', page)
}

/**
 * CalculationTable 分页器改变每页条数
 * @param pageSize 每页条数
 */
const onChangePageSize = (pageSize: number) => {
  emit('onChangePageSize', pageSize)
}

/**
 * 打开CalculationTable过滤器弹窗
 */
const onOpenFilterOverlay = () => {
  emit('onOpenFutureFilterOverlay')
}

/**
 * 打开RawData Ending筛选弹窗
 * @param data 点击单条Calculation数据
 */
const onOpenRawDataEndingDialog = (data: FutureAdjustmentItemType | OptionsAdjustmentItemType) => {
  emit('onOpenRawDataEndingDialog', { filterType: props.tableType, data })
}

/**
 * 打开RawData Realized筛选弹窗
 * @param data 点击单条Calculation数据
 */
const onOpenRawDataRealizedDialog = (data: FutureAdjustmentItemType | OptionsAdjustmentItemType) => {
  emit('onOpenRawDataRealizedDialog', { filterType: props.tableType, data })
}
</script>

<style lang="scss">
.elv-valuation-calculation-content-panel {
  width: 100%;
  height: 100%;
  position: relative;

  .elv-valuation-calculation-content-panel-table-wrapper {
    width: 100%;
    height: calc(100% - 43px);
    position: relative;

    .elv-valuation-calculation-content-panel-table {
      .elv-component-custom-table {
        .elv-component-custom-table-row__cell .cell {
          font-size: 12px;
          font-weight: 500;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;

          .elv-base-cell-icon-title {
            padding: 12px 0px;
          }

          .elv-valuation-calculation-content-panel-table-account {
            img {
              width: 14px;
              height: 14px;
              margin-right: 4px;
            }

            div {
              font-weight: 500 !important;
              font-size: 12px !important;
            }
          }

          .elv-valuation-calculation-content-panel-table-symbol {
            font-size: 13px;
            font-weight: 600;
          }

          .elv-valuation-calculation-content-panel-table-ending,
          .elv-valuation-calculation-content-panel-table-beginning,
          .elv-valuation-calculation-content-panel-table-realized {
            display: flex;
            align-items: center;
            font-size: 13px;
            justify-content: flex-end;
            overflow: hidden;
            font-family: 'Barlow';

            svg {
              cursor: pointer;
              margin-left: 4px;
            }
          }
        }

        .elv-component-custom-table-header__cell .cell {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        .elv-valuation-calculation-content-panel-table-beginning-header {
          cursor: pointer;
          display: flex;
          align-items: center;
          white-space: nowrap;

          span {
            display: flex;
            flex-wrap: nowrap;
            margin-right: 4px;
          }

          svg {
            margin-top: 2px;
          }
        }
      }
    }
  }
}
</style>
